<template>
  <component :is="appMode" />
</template>

<script>
export default {
  components: {
    super: () => import('./super/Index.vue'),
    staff: () => import('./Staff.vue'),
    company: () => import('./Company.vue'),
  },
};
</script>
